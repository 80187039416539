

























































































































































































































































































































































































import { Area, Button, Cell, CellGroup, Tabs, Tab, Checkbox, CheckboxGroup, Dialog, Form, Field, Image, Picker, Popup, Radio, RadioGroup, Uploader, Row, Col, Toast, PullRefresh } from "vant";
import { defineComponent } from "@vue/composition-api";
import { mask } from "vue-the-mask";
import { areaList } from "@vant/area-data";
import { data as contactData } from "./apply-wx-partner/contact";
import { data as subjectData } from "./apply-wx-partner/subject";
import { accountBanks, certTypes, certTypes2388, certTypes2389, financeTypes, idTypes, subjectTypes, VantFile } from "./apply-wx-partner/common";

export default defineComponent({
  directives: {
    mask
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Form.name]: Form,
    [Field.name]: Field,
    [PullRefresh.name]: PullRefresh,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Image.name]: Image,
    [Area.name]: Area,
    [Popup.name]: Popup,
    [Uploader.name]: Uploader,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      refreshing: false,
      showAreaPicker: false,
      areaList,

      showContactIdDocTypePicker: false,
      showIdDocTypePicker: false,
      idTypes,
      showSubjectTypePicker: false,
      subjectTypes,
      showCertTypePicker: false,
      certTypes2388,
      certTypes2389,
      certTypes,
      showFinanceTypePicker: false,
      financeTypes,
      showAccountBankPicker: false,
      accountBanks,

      activeTab: "contact",
      TAB_CONTACT: "contact",
      TAB_SUBJECT: "subject",
      TAB_BUSINESS: "business",
      TAB_BANK: "bank",
      TAB_ADDITION: "addition",
      TAB_SUBMIT: "submit",

      // ---------- apply

      apply: {
        businessCode: "",
        applymentState: "APPLYMENT_STATE_EDITTING",
        applymentStateMsg: "编辑中",
        finishTime: null,
        signUrl: null,
        auditDetail: [] as { field: string; field_name: string; reject_reason: string }[],

        ...contactData,
        ...subjectData,
        // business data
        merchantShortName: "",
        servicePhone: "",
        // bank data
        bankAccountType: "",
        bankAddressCode: "",
        bankAddressCity: "",
        bankName: "",
        accountBank: "",
        accountName: "",
        accountNumber: "",
        // extra data
        legalPersonCommitment: "",
        legalPersonVideoFile: [] as VantFile[],
        legalPersonVideo: "",
        businessAdditionPics: [] as string[],
        businessAdditionMsg: ""
      }
    };
  },
  created() {
    this.loadApplyOrInit();
  },
  methods: {
    canSaveOrApply() {
      return ["APPLYMENT_STATE_EDITTING", "APPLYMENT_STATE_REJECTED"].includes(this.apply.applymentState);
    },
    uploadImage(file: { file: File; status: string; message: string }, callback: (mediaId: string) => void) {
      if (!this.canSaveOrApply()) {
        Toast.fail("当前状态不可上传");
        file.status = "failed";
        file.message = "上传失败";
        return;
      }

      file.status = "uploading";
      file.message = "正在上传";

      const data = new FormData();
      data.append("file", file.file);
      data.append("businessCode", this.apply.businessCode);
      this.$axios
        .post("/api/manage/wx-partner/uploadPhoto", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          const mediaId = res.data?.data;
          if (mediaId) {
            file.status = "done";
            file.message = "";
            callback(mediaId);
          } else {
            file.status = "failed";
            file.message = "上传失败";
          }
        });
    },
    onConfirmArea(data: { name: string; code: string }[]) {
      const { name, code } = data[1] ?? data[0];
      this.apply.bankAddressCity = name;
      this.apply.bankAddressCode = code;
      this.showAreaPicker = false;
    },
    async updateApply() {
      return this.$axios
        .post("/api/manage/wx-partner/updateApplyInfo", this.handleApplyData())
        .then(() => true)
        .catch(() => false);
    },
    doApply() {
      this.$axios.post("/api/manage/wx-partner/doApply", { businessCode: this.apply.businessCode }).then(() => {
        this.$toast("提交成功");
        this.loadApplyOrInit();
      });
    },
    enableByApply() {
      this.$axios.post("/api/manage/wx-partner/enableByApply", { businessCode: this.apply.businessCode }).then(() => {
        this.$toast("启用成功");
        this.loadApplyOrInit();
      });
    },
    handleApplyData() {
      const {
        businessCode,
        contactType,
        contactName,
        contactIdDocType,
        contactIdNumber,
        contactIdDocCopy,
        contactIdDocCopyBack,
        contactPeriodBegin,
        contactPeriodEndType,
        contactPeriodEnd,
        businessAuthorizationLetter,
        contactEmail,
        mobilePhone,
        subjectType,
        financeInstitution,
        licenseCopy,
        licenseNumber,
        merchantName,
        legalPerson,
        periodBegin,
        periodEndType,
        periodEnd,
        certCopy,
        certType,
        certNumber,
        certMerchantName,
        certCompanyAddress,
        certLegalPerson,
        certPeriodBegin,
        certPeriodEndType,
        certPeriodEnd,
        certificateLetterCopy,
        financeLicensePics,
        businessAdditionPics,
        businessAdditionMsg,
        legalPersonCommitment,
        legalPersonVideo,
        bankAccountType,
        bankAddressCode,
        bankAddressCity,
        bankName,
        accountBank,
        accountName,
        accountNumber,
        licenseAddress,
        financeType,
        cardPeriodEndType,
        cardPeriodEnd,
        docPeriodEndType,
        docPeriodEnd,
        authorizeLetterCopy,
        cardPeriodBegin,
        idCardAddress,
        idCardCopy,
        idCardName,
        idCardNational,
        idCardNumber,
        idDocType,
        idHolderType,
        idDocCopy,
        idDocCopyBack,
        idDocName,
        idDocNumber,
        idDocAddress,
        docPeriodBegin,
        // owner,

        merchantShortName,
        servicePhone
      } = this.apply;
      return {
        businessCode,

        contactType,
        contactName,
        contactIdDocType,
        contactIdNumber,
        contactIdDocCopy,
        contactIdDocCopyBack,
        contactPeriodBegin,
        contactPeriodEnd: contactPeriodEndType === "长期" ? "长期" : contactPeriodEnd,
        businessAuthorizationLetter,
        contactEmail,
        mobilePhone,

        subjectType,
        financeInstitution,
        licenseCopy,
        licenseNumber,
        merchantName,
        legalPerson,
        licenseAddress,
        periodBegin,
        periodEnd: periodEndType === "长期" ? "长期" : periodEnd,

        certCopy,
        certType,
        certNumber,
        certMerchantName,
        certCompanyAddress,
        certLegalPerson,
        certPeriodBegin,
        certPeriodEnd: certPeriodEndType === "长期" ? "长期" : certPeriodEnd,
        certificateLetterCopy,
        financeLicensePics: financeLicensePics.join(","),
        financeType,
        authorizeLetterCopy,
        cardPeriodBegin,
        cardPeriodEnd: cardPeriodEndType === "长期" ? "长期" : cardPeriodEnd,
        idCardAddress,
        idCardCopy,
        idCardName,
        idCardNational,
        idCardNumber,
        idDocType,
        idHolderType,
        idDocCopy,
        idDocCopyBack,
        idDocName,
        idDocNumber,
        idDocAddress,
        docPeriodBegin,
        docPeriodEnd: docPeriodEndType === "长期" ? "长期" : docPeriodEnd,
        owner: true,

        merchantShortName,
        servicePhone,
        bankAccountType,
        bankAddressCode,
        bankAddressCity,
        bankName,
        accountBank,
        accountName,
        accountNumber,
        legalPersonCommitment,
        legalPersonVideo,
        businessAdditionPics: businessAdditionPics.join(","),
        businessAdditionMsg
      };
    },
    loadApplyOrInit() {
      function packImageFile(mediaId: string) {
        return { url: "https://lf-jdz.oss-cn-hangzhou.aliyuncs.com/wx-partner/" + mediaId + "?x-oss-process=image/format,webp", isImage: true };
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      function handleImage(apply: Record<string, any>, keys: string[]) {
        for (const key of keys) {
          if (apply[key]) {
            apply[key + "File"] = [packImageFile(apply[key])];
          }
        }
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      function handleImageArray(apply: Record<string, any>, keys: string[]) {
        for (const key of keys) {
          if (apply[key]) {
            apply[key] = apply[key].split(",");
            apply[key + "Files"] = apply[key].map(packImageFile);
          } else {
            apply[key] = [];
            apply[key + "Files"] = [];
          }
        }
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      function handlePeriodEnd(apply: Record<string, any>, keys: string[]) {
        for (const key of keys) {
          if (apply[key] === "长期") {
            apply[key + "Type"] = "长期";
            apply[key] = "";
          } else {
            apply[key + "Type"] = "非长期";
          }
        }
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      function handleBool(apply: Record<string, any>, keys: string[]) {
        for (const key of keys) {
          apply[key] = apply[key] === "true" || apply[key] === "1";
        }
      }

      this.$axios.post("/api/manage/wx-partner/getApply").then(res => {
        const apply = res.data?.data;
        if (!apply) {
          this.$axios.post("/api/manage/wx-partner/initApply").then(res => {
            this.apply.businessCode = res.data?.data?.businessCode;
          });
          return;
        }

        handleImage(apply, ["authorizeLetterCopy", "businessAuthorizationLetter", "certCopy", "certificateLetterCopy", "contactIdDocCopy", "contactIdDocCopyBack", "idCardCopy", "idCardNational", "idDocCopy", "idDocCopyBack", "licenseCopy"]);
        handleImageArray(apply, ["businessAdditionPics", "financeLicensePics"]);
        handlePeriodEnd(apply, ["cardPeriodEnd", "certPeriodEnd", "contactPeriodEnd", "docPeriodEnd", "periodEnd"]);
        handleBool(apply, ["financeInstitution", "owner"]);
        apply.contactIdDocTypeText = idTypes.find(item => item.value === apply.contactIdDocType)?.text;
        apply.idDocTypeText = idTypes.find(item => item.value === apply.idDocType)?.text;
        apply.subjectTypeText = subjectTypes.find(item => item.value === apply.subjectType)?.text;
        apply.certTypeText = certTypes.find(item => item.value === apply.certType)?.text;
        apply.financeTypeText = financeTypes.find(item => item.value === apply.financeType)?.text;
        // console.log(apply);
        this.apply = apply;
        if (this.apply.applymentState !== "APPLYMENT_STATE_EDITTING") {
          this.activeTab = this.TAB_SUBMIT;
        } else {
          this.activeTab = this.TAB_CONTACT;
        }
      });
    }
  }
});

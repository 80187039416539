export const idTypes = [
  { value: "IDENTIFICATION_TYPE_IDCARD", text: "中国大陆居民-身份证" },
  { value: "IDENTIFICATION_TYPE_OVERSEA_PASSPORT", text: "其他国家或地区居民-护照" },
  { value: "IDENTIFICATION_TYPE_HONGKONG_PASSPORT", text: "中国香港居民-来往内地通行证" },
  { value: "IDENTIFICATION_TYPE_MACAO_PASSPORT", text: "中国澳门居民-来往内地通行证" },
  { value: "IDENTIFICATION_TYPE_TAIWAN_PASSPORT", text: "中国台湾居民-来往大陆通行证" },
  { value: "IDENTIFICATION_TYPE_FOREIGN_RESIDENT", text: "外国人居留证" },
  { value: "IDENTIFICATION_TYPE_HONGKONG_MACAO_RESIDENT", text: "港澳居民证" },
  { value: "IDENTIFICATION_TYPE_TAIWAN_RESIDENT", text: "台湾居民证" }
];

export const subjectTypes = [
  { value: "SUBJECT_TYPE_INDIVIDUAL", text: "个体户" },
  { value: "SUBJECT_TYPE_ENTERPRISE", text: "企业" },
  { value: "SUBJECT_TYPE_GOVERNMENT", text: "政府机关" },
  { value: "SUBJECT_TYPE_INSTITUTIONS", text: "事业单位" },
  { value: "SUBJECT_TYPE_OTHERS", text: "社会组织" }
];

export const certTypes2388 = [{ value: "CERTIFICATE_TYPE_2388", text: "事业单位法人证书" }];
export const certTypes2389 = [{ value: "CERTIFICATE_TYPE_2389", text: "统一社会信用代码证书" }];
export const certTypes = [
  { value: "CERTIFICATE_TYPE_2389", text: "统一社会信用代码证书" },
  { value: "CERTIFICATE_TYPE_2394", text: "社会团体法人登记证书" },
  { value: "CERTIFICATE_TYPE_2395", text: "民办非企业单位登记证书" },
  { value: "CERTIFICATE_TYPE_2396", text: "基金会法人登记证书" },
  { value: "CERTIFICATE_TYPE_2520", text: "执业许可证/执业证" },
  { value: "CERTIFICATE_TYPE_2521", text: "基层群众性自治组织特别法人统一社会信用代码证" },
  { value: "CERTIFICATE_TYPE_2522", text: "农村集体经济组织登记证" },
  { value: "CERTIFICATE_TYPE_2399", text: "宗教活动场所登记证" },
  { value: "CERTIFICATE_TYPE_2400", text: "政府部门下发的其他有效证明文件" }
];
export const financeTypes = [
  { value: "BANK_AGENT", text: "银行业" },
  { value: "PAYMENT_AGENT", text: "支付机构" },
  { value: "INSURANCE", text: "保险业" },
  { value: "TRADE_AND_SETTLE", text: "交易及结算类金融机构" },
  { value: "OTHER", text: "其他金融机构" }
];
export const accountBanks = [
  { value: "工商银行", text: "工商银行" },
  { value: "交通银行", text: "交通银行" },
  { value: "招商银行", text: "招商银行" },
  { value: "民生银行", text: "民生银行" },
  { value: "中信银行", text: "中信银行" },
  { value: "浦发银行", text: "浦发银行" },
  { value: "兴业银行", text: "兴业银行" },
  { value: "光大银行", text: "光大银行" },
  { value: "广发银行", text: "广发银行" },
  { value: "平安银行", text: "平安银行" },
  { value: "北京银行", text: "北京银行" },
  { value: "华夏银行", text: "华夏银行" },
  { value: "农业银行", text: "农业银行" },
  { value: "建设银行", text: "建设银行" },
  { value: "邮政储蓄银行", text: "邮政储蓄银行" },
  { value: "中国银行", text: "中国银行" },
  { value: "宁波银行", text: "宁波银行" },
  { value: "其他银行", text: "其他银行" }
];

export type VantFile = {
  url: string;
  isImage: boolean;
};

export const sampleApply = {
  businessCode: "sampleApplyBusinessCode",
  applymentState: "APPLYMENT_STATE_EDITTING",
  applymentStateMsg: "编辑中",
  finishTime: null,
  signUrl: "signUrl",
  /* eslint-disable @typescript-eslint/camelcase */
  auditDetail: [{ field: "x", field_name: "X", reject_reason: "test" }],

  contactType: "LEGAL",
  contactName: "contactName",
  contactIdDocType: "IDENTIFICATION_TYPE_IDCARD",
  contactIdDocTypeText: "中国大陆居民-身份证",
  contactIdNumber: "contactIdNumber",
  contactIdDocCopy: "contactIdDocCopy",
  contactIdDocCopyFile: [] as VantFile[],
  contactIdDocCopyBack: "contactIdDocCopyBack",
  contactIdDocCopyBackFile: [] as VantFile[],
  contactPeriodBegin: "1995-01-01",
  contactPeriodEnd: "1996-01-01",
  contactPeriodEndType: "非长期",
  businessAuthorizationLetter: "businessAuthorizationLetter",
  businessAuthorizationLetterFile: [] as VantFile[],
  contactEmail: "contactEmail",
  mobilePhone: "15700085333",

  subjectType: "SUBJECT_TYPE_INDIVIDUAL",
  subjectTypeText: "个体户",
  financeInstitution: false,
  licenseCopy: "licenseCopy",
  licenseCopyFile: [] as VantFile[],
  licenseNumber: "licenseNumber",
  merchantName: "merchantName",
  legalPerson: "leagalPerson",
  licenseAddress: "licenseAddress",
  periodBegin: "1995-01-01",
  periodEnd: "1996-01-01",
  periodEndType: "非长期",

  certCopy: "certCopy",
  certCopyFile: [] as VantFile[],
  certType: "certType",
  certTypeText: "certTypeText",
  certNumber: "certNumber",
  certMerchantName: "certMerchantName",
  certCompanyAddress: "certComanyAddress",
  certLegalPerson: "certLegalPerson",
  certPeriodBegin: "1995-01-01",
  certPeriodEnd: "1996-01-01",
  certPeriodEndType: "非长期",

  certificateLetterCopy: "certificateLetterCopy",
  certificateLetterCopyFile: [] as VantFile[],

  financeLicensePics: ["financeLicensePics"],
  financeLicensePicsFiles: [] as VantFile[],
  financeType: "financeType",
  financeTypeText: "financeTypeText",

  authorizeLetterCopy: "authorizeLetterCopy",
  authorizeLetterCopyFile: [] as VantFile[],
  cardPeriodBegin: "1995-01-01",
  cardPeriodEnd: "1996-01-01",
  cardPeriodEndType: "非长期",
  idCardAddress: "idCardAddress",
  idCardCopy: "idCardCopy",
  idCardCopyFile: [
    {
      url: "https://lf-jdz.oss-cn-hangzhou.aliyuncs.com/wx-partner/7gyIwFkKvJBeuMh4-na2Mhkiyh9CsU_xljCofOPP3hxt7ySTh29bX17jCbi3Jn6xhp3lJUBKIHVpN8LR4xds7rnZATEXmQ7bbGwN0ZvmPeM?x-oss-process=image/format,webp",
      isImage: true
    }
  ],
  idCardName: "idCardName",
  idCardNational: "idCardNational",
  idCardNationalFile: [] as VantFile[],
  idCardNumber: "idCardNumber",
  idDocType: "IDENTIFICATION_TYPE_IDCARD",
  idDocTypeText: "中国大陆居民-身份证",
  idHolderType: "LEGAL",

  idDocCopy: "idDocCopy",
  idDocCopyFile: [] as VantFile[],
  idDocCopyBack: "idDocCopyBack",
  idDocCopyBackFile: [] as VantFile[],
  idDocName: "idDocName",
  idDocNumber: "idDocNumbner",
  idDocAddress: "idDocAddress",
  docPeriodBegin: "1995-01-01",
  docPeriodEnd: "1996-01-01",
  docPeriodEndType: "非长期",

  owner: true,
  uboInfoList: [],

  // business data
  merchantShortName: "merchantShortName",
  servicePhone: "servicePhone",
  // bank data
  bankAccountType: "BANK_ACCOUNT_TYPE_CORPORATE",
  bankAddressCode: "bankAddressCode",
  bankAddressCity: "bankAddressCity",
  bankName: "bankName",
  accountBank: "accountBank",
  accountName: "accountName",
  accountNumber: "accountNumber",
  // extra data
  legalPersonCommitment: "legalPersonCommitment",
  legalPersonVideoFile: [] as VantFile[],
  legalPersonVideo: "legalPersonVideo",
  businessAdditionPics: ["businessAdditionPics"],
  businessAdditionMsg: "businessAdditionMsg"
};

import { VantFile } from "./common";

export const data = {
  subjectType: "",
  subjectTypeText: "",
  financeInstitution: false,
  licenseCopy: "",
  licenseCopyFile: [] as VantFile[],
  licenseNumber: "",
  merchantName: "",
  legalPerson: "",
  licenseAddress: "",
  periodBegin: "",
  periodEnd: "",
  periodEndType: "非长期",

  certCopy: "",
  certCopyFile: [] as VantFile[],
  certType: "",
  certTypeText: "",
  certNumber: "",
  certMerchantName: "",
  certCompanyAddress: "",
  certLegalPerson: "",
  certPeriodBegin: "",
  certPeriodEnd: "",
  certPeriodEndType: "非长期",

  certificateLetterCopy: "",
  certificateLetterCopyFile: [] as VantFile[],

  financeLicensePics: [] as string[],
  financeLicensePicsFiles: [] as VantFile[],
  financeType: "",
  financeTypeText: "",

  authorizeLetterCopy: "",
  authorizeLetterCopyFile: [] as VantFile[],
  cardPeriodBegin: "",
  cardPeriodEnd: "",
  cardPeriodEndType: "非长期",
  idCardAddress: "",
  idCardCopy: "",
  idCardCopyFile: [] as VantFile[],
  idCardName: "",
  idCardNational: "",
  idCardNationalFile: [] as VantFile[],
  idCardNumber: "",
  idDocType: "IDENTIFICATION_TYPE_IDCARD",
  idDocTypeText: "中国大陆居民-身份证",
  idHolderType: "LEGAL",

  idDocCopy: "",
  idDocCopyFile: [] as VantFile[],
  idDocCopyBack: "",
  idDocCopyBackFile: [] as VantFile[],
  idDocName: "",
  idDocNumber: "",
  idDocAddress: "",
  docPeriodBegin: "",
  docPeriodEnd: "",
  docPeriodEndType: "非长期",

  owner: true,
  uboInfoList: []
};

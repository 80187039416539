import { VantFile } from "./common";

export const data = {
  contactType: "LEGAL",
  contactName: "",
  contactIdDocType: "IDENTIFICATION_TYPE_IDCARD",
  contactIdDocTypeText: "中国大陆居民-身份证",
  contactIdNumber: "",
  contactIdDocCopy: "",
  contactIdDocCopyFile: [] as VantFile[],
  contactIdDocCopyBack: "",
  contactIdDocCopyBackFile: [] as VantFile[],
  contactPeriodBegin: "",
  contactPeriodEnd: "",
  contactPeriodEndType: "非长期",
  businessAuthorizationLetter: "",
  businessAuthorizationLetterFile: [] as VantFile[],
  contactEmail: "",
  mobilePhone: ""
};
